import React, { useState, useCallback } from 'react'
import {
  useCreateRiskListMutation,
  useGetRiskListsQuery,
} from '../../../redux/api/project-risk-api-slice'
import RiskList from './risk-list'
import { toast } from 'react-toastify'
import {
  CreateRiskListFormProps,
  RiskListItem,
} from '../../../shared/interfaces/project/risk/risk-inteface'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'

const CreateRiskListForm: React.FC<CreateRiskListFormProps> = ({ onClose }) => {
  const [riskListName, setRiskListName] = useState('')
  const [createRiskList] = useCreateRiskListMutation()
  const currentProject = useSelector(selectCurrentProject)

  const {
    data: riskLists,
    isLoading: isLoadingRiskLists,
    refetch,
  } = useGetRiskListsQuery()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await createRiskList({ name: riskListName }).unwrap()
      setRiskListName('')
      await refetch()
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
  }

  const handleRiskListNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRiskListName(e.target.value)
    },
    []
  )

  return (
    <div className="rounded-lg bg-white p-4">
      <div className="mb-8">
        <h2 className="mb-3 text-lg font-semibold">Create a New Risk List</h2>
        <form onSubmit={handleSubmit} className="flex items-center space-x-4">
          <div className="flex-grow">
            <label htmlFor="riskListName" className="sr-only">
              Risk List Name
            </label>
            <input
              type="text"
              id="riskListName"
              value={riskListName}
              onChange={handleRiskListNameChange}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Risk List Name"
              required
            />
          </div>
          <button
            type="submit"
            className="rounded-md border border-transparent bg-indigo-600 px-4 py-[10px] text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Create
          </button>
        </form>
      </div>

      <hr className="mb-6" />

      <h3 className="mb-6 text-xl font-semibold">
        <div className="flex items-center">
          <ClipboardDocumentListIcon className="text=gray-900 mr-2 h-8 w-8" />{' '}
          Custom Risk Lists
        </div>
      </h3>
      {isLoadingRiskLists ? (
        <p>Loading risk lists...</p>
      ) : riskLists?.length ? (
        riskLists.map((riskList: RiskListItem) => (
          <div key={riskList.id}>
            <RiskList
              id={riskList.id}
              name={riskList.name}
              projectUuid={currentProject?.uuid}
            />
          </div>
        ))
      ) : (
        <p>No risk lists found.</p>
      )}
    </div>
  )
}

export default CreateRiskListForm
