import React from 'react'

interface CustomRiskListsButtonProps {
  hasCustomRiskListPermission: boolean
  onClick: () => void
}

const CustomRiskListsButton: React.FC<CustomRiskListsButtonProps> = ({
  hasCustomRiskListPermission,
  onClick,
}) => {
  return (
    <div className="group relative">
      <button
        className={`w-36 px-4 py-2 text-left ${
          hasCustomRiskListPermission
            ? 'hover:bg-gray-100'
            : 'cursor-not-allowed text-gray-400'
        }`}
        onClick={onClick}
        disabled={!hasCustomRiskListPermission}
      >
        Custom Risk Lists
      </button>
      {!hasCustomRiskListPermission && (
        <div className="absolute left-full top-0 ml-2 rounded bg-gray-800 p-2 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
          Contact provision to enable!
        </div>
      )}
    </div>
  )
}

export default CustomRiskListsButton
