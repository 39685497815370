import React, { useState, Fragment, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { selectModal, setModal } from '../../redux/application-slice'
import { useUpdateRiskListMutation } from '../../redux/api/project-risk-api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

interface EditRiskListModalProps {
  open: boolean
}

const EditRiskListModal: React.FC<EditRiskListModalProps> = ({ open }) => {
  const [updateRiskList] = useUpdateRiskListMutation()
  const dispatch = useDispatch()
  const onClose = useCallback(() => {
    dispatch(setModal(null))
  }, [dispatch])
  const modalOpen = useSelector(selectModal)
  const [newName, setNewName] = useState(modalOpen?.props?.name)

  const handleSubmit = useCallback(async () => {
    try {
      await updateRiskList({
        id: modalOpen?.props?.id,
        name: newName,
      }).unwrap()
      onClose()
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
  }, [updateRiskList, modalOpen?.props?.id, newName, onClose])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg border border-gray-200 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <Dialog.Title
                as="h3"
                className="mb-3 text-lg font-medium leading-6 text-gray-900"
              >
                Edit Risk List
              </Dialog.Title>
              <div className="mt-2">
                <input
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter new name"
                />
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSubmit}
                  disabled={!newName || newName === modalOpen?.props?.name}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default EditRiskListModal
