import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../redux/application-slice'
import { MODAL_TYPES } from '../../modals/modal-controller'

interface RiskProps {
  id: string
  riskName: string
  queryText: string
}

const Risk: React.FC<RiskProps> = ({ id, riskName, queryText }) => {
  const dispatch = useDispatch()

  const handleEditClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.EDIT_CUSTOM_RISK,
        props: { id, riskName, queryText },
      })
    )
  }

  const handleDeleteClick = (e: React.MouseEvent) => {
    dispatch(
      setModal({
        modal: MODAL_TYPES.DELETE_CUSTOM_RISK,
        props: {
          id,
          risk_name: riskName,
        },
      })
    )
  }

  return (
    <div className="mb-2 flex w-full max-w-full items-center justify-between gap-x-2 rounded-lg border border-gray-200 p-4">
      <div className="min-w-0 flex-grow">
        <div className="truncate text-lg font-medium">{riskName}</div>
        <div className="text-sm text-gray-600">{queryText}</div>
      </div>
      <div className="flex shrink-0 space-x-2">
        <button
          onClick={handleEditClick}
          className="rounded px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
          aria-label="Edit Details"
        >
          Edit Details
        </button>
        <button
          onClick={handleDeleteClick}
          className="rounded px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100"
          aria-label="Delete"
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default Risk
