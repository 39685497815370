import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { useGetDocumentTemplatesQuery } from '../../redux/api/document-change-template-api-slice'
import { DocumentChangeTemplateInterface } from '../../shared/interfaces/project/document/document-change/document-change-template.interface'
import { useSelector } from 'react-redux'
import { selectCCDCVersion } from '../../redux/editor-slice'
import { selectDocumentChange } from '../../redux/viewer-slice'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface DocumentChangeTemplateReferenceComboBoxProps {
  selectedReference: DocumentChangeTemplateInterface[]
  setSelectedReference: React.Dispatch<
    React.SetStateAction<DocumentChangeTemplateInterface[]>
  >
  onChangeReferencedDocumentChangeTemplates: () => void
  startingValue?: string
}

export default function DocumentChangeTemplateReferenceComboBox({
  selectedReference,
  setSelectedReference,
  onChangeReferencedDocumentChangeTemplates,
  startingValue,
}: DocumentChangeTemplateReferenceComboBoxProps) {
  const selectedDocumentChange = useSelector(selectDocumentChange)
  const [query, setQuery] = useState('')
  const ccdcVersion = useSelector(selectCCDCVersion)
  const { data } = useGetDocumentTemplatesQuery(ccdcVersion)
  const filteredDocumentChangeTemplates = useMemo(() => {
    const filteredData =
      query === ''
        ? data
        : data?.filter((template) => {
            return template.clause_reference
              .toLowerCase()
              .includes(query.toLowerCase())
          })
    return [...(filteredData ?? []), ...(selectedReference ?? [])]
  }, [data, query, selectedReference])

  useEffect(() => {
    if (selectedDocumentChange && data) {
      const newDocumentChangeTemplate: DocumentChangeTemplateInterface = {
        clause_reference: selectedDocumentChange.original_clause_reference,
        clause_content: selectedDocumentChange.original_clause,
        document_name: '',
        id: '',
      }
      setSelectedReference([newDocumentChangeTemplate])
    }
    if (startingValue) {
      setQuery(startingValue)
    }
  }, [data, selectedDocumentChange, setSelectedReference, startingValue])

  const onSelectReference = useCallback(
    (e: DocumentChangeTemplateInterface[]) => {
      setSelectedReference(e)
      onChangeReferencedDocumentChangeTemplates()
    },
    [setSelectedReference, onChangeReferencedDocumentChangeTemplates]
  )

  const onChangeQuery = useCallback((e) => {
    setQuery(e.target.value)
  }, [])

  const comboBoxDisplayValue = useCallback(
    (template: DocumentChangeTemplateInterface[]) => {
      return template.reduce((acc, t) => {
        if (acc) {
          acc += ', '
        }
        return (acc += t.clause_reference)
      }, '')
    },
    []
  )

  const referenceComboboxClassNames = useCallback(({ active }) => {
    return classNames(
      'relative cursor-default select-none py-2 pl-3 pr-9',
      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
    )
  }, [])

  return (
    <Combobox
      as="div"
      value={selectedReference}
      multiple
      onChange={onSelectReference}
    >
      <div className="relative mt-2">
        <Combobox.Input
          className="border-0 bg-white shadow-sm py-1.5 pr-10 pl-3 rounded-md ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 ring-inset focus:ring-inset w-full text-gray-900 sm:text-sm sm:leading-6"
          onChange={onChangeQuery}
          displayValue={comboBoxDisplayValue}
          placeholder={'Select original clause'}
        />
        <Combobox.Button className="right-0 absolute inset-y-0 flex items-center px-2 rounded-r-md focus:outline-none">
          <ChevronUpDownIcon
            className="w-4 h-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredDocumentChangeTemplates &&
          filteredDocumentChangeTemplates.length > 0 && (
            <Combobox.Options className="z-10 absolute bg-white ring-opacity-5 shadow-lg mt-1 py-1 rounded-md ring-1 ring-black w-full max-h-60 text-base sm:text-sm overflow-auto focus:outline-none">
              {filteredDocumentChangeTemplates?.map(
                (template: DocumentChangeTemplateInterface) => (
                  <Combobox.Option
                    key={template.id}
                    value={template}
                    className={referenceComboboxClassNames}
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected ? 'font-semibold' : ''
                          )}
                        >
                          {template.clause_reference}
                          {' > '}
                          <span
                            className={classNames(
                              'text-xs text-gray-500',
                              active ? 'text-white' : ''
                            )}
                          >
                            {template.document_name}
                          </span>
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                )
              )}
            </Combobox.Options>
          )}
      </div>
    </Combobox>
  )
}
