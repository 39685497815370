// viewer slice

import { createSlice } from '@reduxjs/toolkit'
import { Quads } from '../shared/interfaces/quads.interface'
import { Highlight } from '../shared/interfaces/project/document/highlight/highlight.interface'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'
import { DocumentBlacklineSegment } from '../shared/interfaces/project/document/blackline/document-blackline.interface'
import { DocumentChange } from '../shared/interfaces/project/document/changes/document-change.interface'

export interface TextSelected {
  quads: { [page: number]: Quads[] }
  text?: string
  pageNumber: number
  highlighted?: boolean
}

export interface SelectedRisk {
  riskID: number
  labelID: number
}

export type SidebarTabType =
  | 'chat'
  | 'bookmarks'
  | 'contents'
  | 'search'
  | 'risks'

export interface TemporaryHighlight {
  pdfCoords?: boolean
  quads: Quads[]
  page: number
  id?: string
  colourClass?: string
}

export interface SelectedSource {
  quads: Quads[]
  page: number
  id: string
  isPrimary: boolean
  documentUUID: string
}

export interface NavigationHistory {
  page: number
  route?: string
}

interface ViewerState {
  totalPages: number
  currentPage: number
  currentSidebarWidth: string
  zoomLevel: number
  textSelected: TextSelected | null
  leftPaneOpen: boolean
  newRevision: Revision | null
  openRevision: Revision | null
  showHighlights: boolean
  documentLoaded: boolean
  highlightSelected: Highlight | null
  navigationHistory: NavigationHistory[]
  temporaryHighlight: TemporaryHighlight[] | null
  cursorTool: string
  sidebarTab: SidebarTabType
  selectedDocumentChange: DocumentChange | null
  highlightedDocumentChange?: string
  selectedRisk?: SelectedRisk | null
  customLabelOpen: boolean | number
  iconMenuOpen: number | null
  bookmarkTab: 'bookmarks' | 'revisions' | 'labels'
  selectedCustomLabel: number | null
  currentDocumentViewerWidth: string
  editorMode: boolean
  documentViewerSidebarOpen: 'search' | 'toc' | 'definitions' | null
  selectedSources: SelectedSource[] | null
  clauseSelectedDocuments: ProjectDocumentMetadata[] | null
  explainQuery: string | null
  selectedBlacklineSegments: DocumentBlacklineSegment[] | null
  clickCoords: { x: number; y: number; page: number } | null
  taskOpen: number | null
  selectedRevision: Revision | null
  selectedRevisionEdit: boolean
  showRevisionHighlights?: boolean
  showCustomLabels?: boolean
  hasZoomedRecently?: boolean
  isSearching: boolean
  selectedAddendum: number | null
  showDefinitions: boolean
}

const initialState: ViewerState = {
  totalPages: 0,
  currentPage: 1,
  currentSidebarWidth: '400px',
  currentDocumentViewerWidth: '400px',
  zoomLevel: 1,
  textSelected: null,
  leftPaneOpen: true,
  newRevision: null,
  openRevision: null,
  showHighlights: true,
  documentLoaded: true,
  highlightSelected: null,
  navigationHistory: [],
  temporaryHighlight: null,
  cursorTool: 'TextSelect',
  sidebarTab: 'chat',
  selectedRisk: null,
  customLabelOpen: false,
  iconMenuOpen: null,
  bookmarkTab: 'bookmarks',
  selectedCustomLabel: null,
  editorMode: false,
  documentViewerSidebarOpen: null,
  selectedSources: null,
  clauseSelectedDocuments: null,
  explainQuery: null,
  selectedBlacklineSegments: null,
  clickCoords: null,
  taskOpen: null,
  selectedDocumentChange: null,
  selectedRevision: null,
  selectedRevisionEdit: false,
  showRevisionHighlights: true,
  showCustomLabels: true,
  hasZoomedRecently: false,
  isSearching: false,
  selectedAddendum: null,
  showDefinitions: true,
}

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setCurrentSidebarWidth: (state, action) => {
      state.currentSidebarWidth = action.payload
    },
    setDocumentViewerWidth: (state, action) => {
      state.currentDocumentViewerWidth = action.payload
    },
    setZoomLevel: (state, action) => {
      state.zoomLevel = action.payload
    },
    setTextSelected: (
      state,
      action: {
        payload: TextSelected | null
        type: string
      }
    ) => {
      if (!action.payload) {
        state.textSelected = null
        return
      }
      const convertedQuadsDict: { [page: number]: Quads[] } = {}
      for (const page in action.payload.quads) {
        const convertedQuads = action.payload.quads[page].map((quad) => {
          return {
            x1: quad.x4,
            x2: quad.x3,
            x3: quad.x2,
            x4: quad.x1,
            y1: quad.y4,
            y2: quad.y3,
            y3: quad.y2,
            y4: quad.y1,
            page: quad.page,
          }
        })
        convertedQuadsDict[parseInt(page)] = convertedQuads
      }
      //Transform text selected into expected Format
      const mappedPayload: TextSelected = {
        ...action.payload,
        quads: convertedQuadsDict,
      }
      state.textSelected = mappedPayload
    },
    setLeftPaneOpen: (state, action) => {
      state.leftPaneOpen = action.payload
    },
    setNewRevision: (state, action) => {
      state.newRevision = action.payload
    },
    setOpenRevision: (state, action) => {
      state.openRevision = action.payload
    },
    setShowHighlights: (state, action) => {
      state.showHighlights = action.payload
    },
    setDocumentLoaded: (state, action) => {
      state.documentLoaded = action.payload
    },
    setHighlightSelected: (state, action) => {
      state.highlightSelected = action.payload
    },
    setNavigationHistory: (
      state,
      action: {
        payload: NavigationHistory[]
        type: string
      }
    ) => {
      state.navigationHistory = action.payload
    },
    addNavigationHistory: (
      state,
      action: {
        payload: NavigationHistory
        type: string
      }
    ) => {
      const currentState = state.navigationHistory
      state.navigationHistory = [...currentState, action.payload]
    },
    setTemporaryHighlight: (
      state,
      action: {
        payload: TemporaryHighlight[] | null
        type: string
      }
    ) => {
      state.temporaryHighlight = action.payload
    },
    setCursorTool: (state, action) => {
      state.cursorTool = action.payload
    },
    setSelectedDocumentChange: (
      state,
      action: {
        payload: DocumentChange | null
        type: string
      }
    ) => {
      state.selectedDocumentChange = action.payload
    },
    setHighlightedDocumentChange: (state, action) => {
      state.highlightedDocumentChange = action.payload
    },
    setSidebarTab: (
      state,
      action: {
        payload: SidebarTabType
        type: string
      }
    ) => {
      state.sidebarTab = action.payload
    },
    setSelectedRisk: (
      state,
      action: {
        payload: SelectedRisk | null
        type: string
      }
    ) => {
      state.selectedRisk = action.payload
    },
    setCustomLabelOpen: (
      state,
      action: {
        payload: boolean | number
        type: string
      }
    ) => {
      state.customLabelOpen = action.payload
    },
    setIconMenuOpen: (
      state,
      action: {
        payload: number | null
        type: string
      }
    ) => {
      state.iconMenuOpen = action.payload
    },
    setBookmarkTab: (
      state,
      action: {
        payload: 'bookmarks' | 'revisions' | 'labels'
        type: string
      }
    ) => {
      state.bookmarkTab = action.payload
    },
    setSelectedCustomLabel: (
      state,
      action: {
        payload: number | null
        type: string
      }
    ) => {
      state.selectedCustomLabel = action.payload
    },
    setDocumentViewerSidebarOpen: (
      state,
      action: {
        payload: 'search' | 'toc' | 'definitions' | null
        type: string
      }
    ) => {
      state.documentViewerSidebarOpen = action.payload
    },
    setSelectedSources: (
      state,
      action: {
        payload: SelectedSource[] | null
        type: string
      }
    ) => {
      state.selectedSources = action.payload
    },
    setClauseSelectedDocuments: (
      state,
      action: {
        payload: ProjectDocumentMetadata[] | null
        type: string
      }
    ) => {
      state.clauseSelectedDocuments = action.payload
    },
    setExplainQuery: (
      state,
      action: {
        payload: string | null
        type: string
      }
    ) => {
      state.explainQuery = action.payload
    },
    setSelectedBlacklineSegments: (
      state,
      action: {
        payload: DocumentBlacklineSegment[] | null
        type: string
      }
    ) => {
      state.selectedBlacklineSegments = action.payload
    },
    setClickCoords: (
      state,
      action: {
        payload: { x: number; y: number; page: number } | null
        type: string
      }
    ) => {
      state.clickCoords = action.payload
    },
    setTaskOpen: (
      state,
      action: {
        payload: number | null
        type: string
      }
    ) => {
      state.taskOpen = action.payload
    },
    setSelectedRevision: (
      state,
      action: {
        payload: Revision | null
        type: string
      }
    ) => {
      state.selectedRevision = action.payload
    },
    setSelectedRevisionEdit: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.selectedRevisionEdit = action.payload
    },
    setShowRevisionHighlights: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.showRevisionHighlights = action.payload
    },
    setShowCustomLabels: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.showCustomLabels = action.payload
    },
    setHasZoomedRecently: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.hasZoomedRecently = action.payload
    },
    setIsSearching: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.isSearching = action.payload
    },
    setSelectedAddendum: (
      state,
      action: {
        payload: number | null
        type: string
      }
    ) => {
      state.selectedAddendum = action.payload
    },
    setShowDefinitions: (
      state,
      action: {
        payload: boolean
        type: string
      }
    ) => {
      state.showDefinitions = action.payload
    },
  },
})

export const {
  setTotalPages,
  setCurrentPage,
  setCurrentSidebarWidth,
  setDocumentViewerWidth,
  setZoomLevel,
  setTextSelected,
  setLeftPaneOpen,
  setNewRevision,
  setShowHighlights,
  setDocumentLoaded,
  setOpenRevision,
  setHighlightSelected,
  setNavigationHistory,
  addNavigationHistory,
  setTemporaryHighlight,
  setCursorTool,
  setSidebarTab,
  setSelectedDocumentChange,
  setHighlightedDocumentChange,
  setSelectedRisk,
  setCustomLabelOpen,
  setIconMenuOpen,
  setBookmarkTab,
  setSelectedCustomLabel,
  setDocumentViewerSidebarOpen,
  setSelectedSources,
  setClauseSelectedDocuments,
  setSelectedBlacklineSegments,
  setExplainQuery,
  setClickCoords,
  setTaskOpen,
  setSelectedRevision,
  setSelectedRevisionEdit,
  setShowRevisionHighlights,
  setShowCustomLabels,
  setHasZoomedRecently,
  setIsSearching,
  setSelectedAddendum,
  setShowDefinitions,
} = viewerSlice.actions

export const selectCurrentPage = (state) => state.viewer.currentPage
export const selectCurrentSidebarWidth = (state) =>
  state.viewer.currentSidebarWidth
export const selectDocumentViewerWidth = (state) =>
  state.viewer.currentDocumentViewerWidth
export const selectZoomLevel = (state) => state.viewer.zoomLevel
export const selectTextSelected = (state: {
  viewer: {
    textSelected: TextSelected | null
  }
}) => state.viewer.textSelected
export const selectLeftPaneOpen = (state) => state.viewer.leftPaneOpen
export const selectTotalPages = (state) => state.viewer.totalPages
export const selectNewRevision = (state) => state.viewer.newRevision
export const selectShowHighlights = (state) => state.viewer.showHighlights
export const selectDocumentLoaded = (state) => state.viewer.documentLoaded
export const selectOpenRevision = (state) => state.viewer.openRevision
export const selectDocumentChange = (state: {
  viewer: {
    selectedDocumentChange: DocumentChange | null
  }
}) => state.viewer.selectedDocumentChange
export const selectCursorTool = (state) => state.viewer.cursorTool
export const selectSidebarTab = (state: {
  viewer: { sidebarTab: SidebarTabType }
}) => state.viewer.sidebarTab
export const selectHighlightSelected = (state) => state.viewer.highlightSelected
export const selectNavigationHistory = (state: {
  viewer: { navigationHistory: NavigationHistory[] }
}) => state.viewer.navigationHistory
export const selectTemporaryHighlight = (state: {
  viewer: { temporaryHighlight: TemporaryHighlight[] | null }
}) => state.viewer.temporaryHighlight
export const selectHighlightedDocumentChange = (state) =>
  state.viewer.highlightedDocumentChange
export const selectSelectedRisk = (state) => state.viewer.selectedRisk
export const selectCustomLabelOpen = (state) => state.viewer.customLabelOpen
export const selectTaskOpen = (state: {
  viewer: { taskOpen: number | null }
}) => state.viewer.taskOpen
export const selectIconMenuOpen = (state) => state.viewer.iconMenuOpen
export const selectSelectedCustomLabel = (state: {
  viewer: { selectedCustomLabel: number | null }
}) => state.viewer.selectedCustomLabel
export const selectBookmarkTab = (state: {
  viewer: { bookmarkTab: 'bookmarks' | 'revisions' | 'labels' }
}) => state.viewer.bookmarkTab
export const selectDocumentViewerSidebarOpen = (state: {
  viewer: {
    documentViewerSidebarOpen: 'search' | 'toc' | 'definitions' | null
  }
}) => state.viewer.documentViewerSidebarOpen
export const selectSelectedSources = (state: {
  viewer: { selectedSources: SelectedSource[] | null }
}) => state.viewer.selectedSources
export const selectClauseSelectedDocuments = (state: {
  viewer: {
    clauseSelectedDocuments: ProjectDocumentMetadata[] | null
  }
}) => state.viewer.clauseSelectedDocuments
export const selectSelectedBlacklineSegments = (state: {
  viewer: { selectedBlacklineSegments: DocumentBlacklineSegment[] | null }
}) => state.viewer.selectedBlacklineSegments
export const selectExplainQuery = (state: {
  viewer: { explainQuery: string | null }
}) => state.viewer.explainQuery
export const selectClickCoords = (state: {
  viewer: { clickCoords: { x: number; y: number; page: number } | null }
}) => state.viewer.clickCoords
export const selectSelectedRevision = (state: {
  viewer: { selectedRevision: Revision | null }
}) => state.viewer.selectedRevision
export const selectSelectedRevisionEdit = (state: {
  viewer: { selectedRevisionEdit: boolean }
}) => state.viewer.selectedRevisionEdit
export const selectShowRevisionHighlights = (state: {
  viewer: { showRevisionHighlights: boolean }
}) => state.viewer.showRevisionHighlights
export const selectShowCustomLabels = (state: {
  viewer: { showCustomLabels: boolean }
}) => state.viewer.showCustomLabels
export const selectHasZoomedRecently = (state: {
  viewer: { hasZoomedRecently: boolean }
}) => state.viewer.hasZoomedRecently
export const selectIsSearching = (state: {
  viewer: { isSearching: boolean }
}) => state.viewer.isSearching
export const selectSelectedAddendum = (state: {
  viewer: { selectedAddendum: number | null }
}) => state.viewer.selectedAddendum
export const selectShowDefinitions = (state: {
  viewer: { showDefinitions: boolean }
}) => state.viewer.showDefinitions

export const viewerReducer = viewerSlice.reducer
