import React, { useCallback, useMemo, useState } from 'react'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import { useGetDocumentsListByProjectQuery } from '../../../redux/api-slice'
import {
  selectCurrentCommentDocuments,
  selectCurrentProject,
  setModal,
} from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { POSTHOG } from '../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import { MODAL_TYPES } from '../../modals/modal-controller'
import DocumentListboxMulti from '../../document-listbox/document-listbox-multi'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePopper } from 'react-popper'
import { Popover } from '@headlessui/react'
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import CustomLabelFilter from './custom-label-filter'
import {
  selectCommentWorkflowFilterState,
  selectCommentWorkflowFilterStateQueryParams,
  setCommentWorkflowFilterState,
} from '../../../redux/workflow-slice'
import RevisionAuthorFilter from './revision-author-filter'
import RevisionStatusFilter from './revision-status-filter'
import RevisionDateFilter from './revision-date-filter'
import { Tooltip } from 'react-tooltip'

interface CommentTableHeaderProps {
  revisionsData: Revision[]
}

const CommentTableHeader: React.FC<CommentTableHeaderProps> = ({
  revisionsData,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const currentCommentDocuments = useSelector(selectCurrentCommentDocuments)
  const revisionParams = useSelector(
    selectCommentWorkflowFilterStateQueryParams
  )
  const posthog = usePostHog()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject
      ? { projectId: currentProject?.id, latest_version_only: true }
      : skipToken
  )
  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  const commentWorkflowFilterState = useSelector(
    selectCommentWorkflowFilterState
  )

  const [exportButtonRef, setExportButtonRef] =
    useState<HTMLButtonElement | null>(null)
  const [exportPanelRef, setExportPanelRef] = useState<HTMLDivElement | null>(
    null
  )
  const { styles: exportStyles, attributes: exportAttributes } = usePopper(
    exportButtonRef,
    exportPanelRef
  )

  const onExcelExport = useCallback(() => {
    posthog?.capture(POSTHOG.download_comment_table, {
      project_uuid: currentProject?.uuid,
    })
    dispatch(setModal({ modal: MODAL_TYPES.EXPORT_OPTIONS, revisionParams }))
  }, [revisionParams, currentProject?.uuid, posthog, dispatch])

  const onChangeSearchQuery = useCallback(
    (e) => {
      dispatch(
        setCommentWorkflowFilterState({
          ...commentWorkflowFilterState,
          searchQuery: e.target.value,
        })
      )
    },
    [dispatch, commentWorkflowFilterState]
  )

  const onClickReconcileComments = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    navigate(
      `/${currentProject?.uuid}/commentviewer${
        currentCommentDocuments?.length === 1
          ? `?documentId=${currentCommentDocuments[0]?.uuid}`
          : ''
      }`
    )
  }, [currentProject?.uuid, navigate, currentCommentDocuments])

  const setDocumentsFilter = useCallback(
    (documents: ProjectDocumentMetadata[]) => {
      dispatch(
        setCommentWorkflowFilterState({
          ...commentWorkflowFilterState,
          documentsFilter: documents,
        })
      )
    },
    [dispatch, commentWorkflowFilterState]
  )
  return (
    <>
      <div
        className={
          'flex w-full flex-col space-y-2 border-y border-gray-100 p-2 text-xs'
        }
      >
        <div className="flex justify-between items-center">
          <div className={'flex flex-1 flex-col gap-2'}>
            <div className="flex gap-2 max-w-[1000px]">
              <div className={'relative flex flex-1 items-center'}>
                <MagnifyingGlassIcon className="left-1.5 absolute w-4 text-gray-500" />
                <input
                  className="border-0 bg-white py-0.5 pl-6 rounded-md ring-1 ring-gray-300 focus:ring-2 focus:ring-indigo-600 ring-inset focus:ring-inset w-full text-gray-900 sm:text-sm sm:leading-6 overflow-hidden"
                  placeholder="Search revisions and comments"
                  type={'text'}
                  onChange={onChangeSearchQuery}
                  value={commentWorkflowFilterState.searchQuery}
                />
              </div>
              {documents && (
                <div className="flex-1">
                  <DocumentListboxMulti
                    documents={documents}
                    selectedDocuments={
                      commentWorkflowFilterState.documentsFilter ?? []
                    }
                    setSelectedDocuments={setDocumentsFilter}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-2 max-w-[1000px]">
              <div className="flex-1">
                <CustomLabelFilter />
              </div>
              <div className="flex-1">
                <RevisionAuthorFilter revisionsData={revisionsData} />
              </div>
              <div className="flex-1">
                <RevisionStatusFilter />
              </div>
            </div>
            <RevisionDateFilter />
          </div>
          <div>
            <Popover className="relative flex justify-center items-center">
              <Popover.Button
                className={'rounded hover:bg-gray-200'}
                tabIndex={-1}
                disabled={documentProcessingCount > 0 || !documents?.length}
                data-tooltip-id={
                  documentProcessingCount === 0 && documents?.length
                    ? ''
                    : `export-comments-id`
                }
                data-tooltip-content="Export will be available when document processing is complete"
                ref={setExportButtonRef}
              >
                <div className={'p-1'}>
                  <EllipsisVerticalIcon width={24} />
                </div>
              </Popover.Button>

              <Popover.Panel
                ref={setExportPanelRef}
                style={exportStyles.popper}
                {...exportAttributes.popper}
                className="z-50 bg-white shadow border rounded"
              >
                <button
                  className="hover:bg-gray-100 px-4 py-2 w-36 text-left"
                  onClick={onClickReconcileComments}
                >
                  Reconcile comments
                </button>
                <button
                  className="hover:bg-gray-100 px-4 py-2 w-36 text-left"
                  onClick={onExcelExport}
                >
                  Export to Excel
                </button>
              </Popover.Panel>
            </Popover>
          </div>
        </div>
      </div>
      <Tooltip id="export-comments-id" />
    </>
  )
}

export default CommentTableHeader
