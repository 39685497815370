import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import {
  useCreateRiskExportMutation,
  useGetDocumentsListByProjectQuery,
} from '../../../redux/api-slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import RiskReviewCard from './risk-review-card'
import {
  ProjectRisk,
  ProjectRiskList,
  RiskStatus,
} from '../../../shared/interfaces/project/risk/risk-inteface'
import {
  useGetProjectRiskQuery,
  useGetProjectRiskStatusStatsQuery,
} from '../../../redux/api/project-risk-api-slice'
import RiskReviewSortListBox from './risk-review-sort-listbox'
import RiskReviewListButton from './risk-review-list-button'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'
import ExportPopover from '../workflow-components/export-popover'
import { Tooltip } from 'react-tooltip'
import FilterDisplay, {
  WorkflowFilter,
} from '../workflow-components/filter-display'
import { useSearchParams } from 'react-router-dom'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import DocumentListboxMulti from '../../document-listbox/document-listbox-multi'
import {
  selectRiskWorkflowFilterState,
  setRiskWorkflowFilterState,
} from '../../../redux/workflow-slice'

interface RiskStatusStats {
  NEEDS_REVIEW: number
  NOT_APPROVED: number
  APPROVED: number
  NO_STATUS: number
}

const RiskReviewPage: React.FC = () => {
  const currentProject = useSelector(selectCurrentProject)

  const [searchQuery, setSearchQuery] = useState('')
  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[] | null
  >(null)
  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const [filter, setFilter] = useState<WorkflowFilter>({})
  const { currentData: riskStatusStats } = useGetProjectRiskStatusStatsQuery(
    currentProject?.uuid ? currentProject.uuid : skipToken
  )
  const [riskPollingInterval, setRiskPollingInterval] = useState<
    number | undefined
  >(undefined)
  const dispatch = useDispatch()
  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)
  const { currentData: projectRisks } = useGetProjectRiskQuery(
    riskWorkflowFilterState?.projectUUID
      ? {
          projectUUID: riskWorkflowFilterState?.projectUUID,
          status: riskWorkflowFilterState?.status,
          sort: riskWorkflowFilterState?.sort,
        }
      : skipToken,
    {
      pollingInterval: riskPollingInterval,
    }
  )
  useEffect(() => {
    dispatch(setRiskWorkflowFilterState({ projectUUID: currentProject?.uuid }))
  }, [currentProject?.uuid, dispatch])

  useEffect(() => {
    if (!projectRisks || projectRisks?.some((r) => r.processing_status === 0)) {
      setRiskPollingInterval(5000)
    } else {
      setRiskPollingInterval(undefined)
    }
  }, [projectRisks])
  const posthog = usePostHog()
  const [selectedRiskList, setSelectedRiskList] =
    useState<ProjectRiskList | null>(null)
  const [createRiskExport] = useCreateRiskExportMutation()
  const [searchParams] = useSearchParams()
  const commentUUID = searchParams.get('comment')

  const riskStatusStatsMapped = useMemo(() => {
    if (!riskStatusStats) {
      return {
        NEEDS_REVIEW: 0,
        NOT_APPROVED: 0,
        APPROVED: 0,
        NO_STATUS: 0,
      }
    }
    const riskStatusStatsMapped: RiskStatusStats = {
      NEEDS_REVIEW: 0,
      NOT_APPROVED: 0,
      APPROVED: 0,
      NO_STATUS: 0,
    }
    for (const stats of riskStatusStats) {
      switch (stats.status) {
        case RiskStatus.NEEDS_REVIEW:
          riskStatusStatsMapped.NEEDS_REVIEW = stats.count
          break
        case RiskStatus.NOT_APPROVED:
          riskStatusStatsMapped.NOT_APPROVED = stats.count
          break
        case RiskStatus.APPROVED:
          riskStatusStatsMapped.APPROVED = stats.count
          break
        case RiskStatus.NO_STATUS:
          riskStatusStatsMapped.NO_STATUS = stats.count
          break
      }
    }
    return riskStatusStatsMapped
  }, [riskStatusStats])

  const onExcelExport = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    createRiskExport({
      projectId: currentProject?.uuid,
      type: 'xls',
      documentUuids: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      riskList: selectedRiskList?.id,
      riskSort: riskWorkflowFilterState?.sort?.toString(),
      searchQuery,
    })
      .unwrap()
      .then((response) => {
        window.open(response.export_url, '_blank')
      })
    posthog?.capture(POSTHOG.export_risk_excel, {
      project_uuid: currentProject?.uuid,
    })
  }, [
    createRiskExport,
    currentProject?.uuid,
    posthog,
    searchQuery,
    selectedDocuments,
    selectedRiskList?.id,
    riskWorkflowFilterState?.sort,
  ])

  const onExcelPDF = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    createRiskExport({
      projectId: currentProject?.uuid,
      type: 'pdf',
      documentUuids: selectedDocuments?.length
        ? selectedDocuments.map((d) => d.uuid)
        : undefined,
      riskList: selectedRiskList?.id,
      riskSort: riskWorkflowFilterState?.sort?.toString(),
      searchQuery,
    })
      .unwrap()
      .then((response) => {
        window.open(response.export_url, '_blank')
      })
    posthog?.capture(POSTHOG.export_risk_pdf, {
      project_uuid: currentProject?.uuid,
    })
  }, [
    createRiskExport,
    currentProject?.uuid,
    posthog,
    searchQuery,
    selectedDocuments,
    selectedRiskList?.id,
    riskWorkflowFilterState?.sort,
  ])

  useEffect(() => {
    if (!commentUUID) {
      return
    }
    setFilter((f) => {
      return { ...f, comment_uuid: commentUUID }
    })
  }, [commentUUID])

  const filteredRisks = useMemo(() => {
    let risks: ProjectRisk[] = []
    if (!projectRisks) {
      return []
    }
    if (!selectedDocuments?.length) {
      risks = projectRisks
    } else {
      risks = projectRisks?.reduce<ProjectRisk[]>((acc, cur) => {
        const doc_uuids = cur.sources?.map((c) => c.document_segment.document)
        const relevantDocUUIDs = selectedDocuments
          ?.filter((d) => doc_uuids?.includes(d.uuid))
          .map((d) => d.uuid)
        if (relevantDocUUIDs?.length) {
          const filteredSources = cur.sources?.filter((s) =>
            relevantDocUUIDs.includes(
              s.document_segment.document?.toString() ?? ''
            )
          )
          const filteredRisk = {
            ...cur,
            sources: filteredSources,
          }
          return [...acc, filteredRisk]
        }
        return acc
      }, [])
    }
    return risks
  }, [projectRisks, selectedDocuments])

  const risksToDisplay = useMemo(() => {
    if (filter?.comment_uuid) {
      return filteredRisks?.filter((r) => {
        return r?.comments?.some((c) => c?.id === filter.comment_uuid)
      })
    }
    if (!selectedRiskList && !searchQuery) {
      return filteredRisks
    }
    const risks = selectedRiskList
      ? filteredRisks?.filter((r) => {
          const riskListIds = r.risk_lists?.map((rl) => rl.id)
          if (!riskListIds) {
            return false
          }
          return riskListIds.includes(selectedRiskList?.id)
        })
      : filteredRisks
    if (searchQuery) {
      return risks.filter((r) => {
        return r?.risk_name?.toLowerCase().includes(searchQuery.toLowerCase())
      })
    }
    return risks
  }, [selectedRiskList, searchQuery, filter, filteredRisks])

  const onChangeSearchQuery = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value)
    },
    []
  )

  const getRiskList = useMemo(() => {
    if (!projectRisks) {
      return []
    }
    const riskLists = projectRisks
      .filter((r) => (r.risk_lists?.length ?? 0) > 0)
      .map((r) => r.risk_lists)
      .flat()
    return riskLists.reduce<ProjectRiskList[]>((acc, cur) => {
      if (!cur) {
        return acc
      }
      const existingRiskList = acc.find((a) => a.id === cur.id)
      if (existingRiskList) {
        return acc
      }
      return [...acc, cur]
    }, [])
  }, [projectRisks])

  const onClickFilterApproved = useCallback(() => {
    dispatch(
      setRiskWorkflowFilterState({
        status: RiskStatus.APPROVED,
        ...riskWorkflowFilterState,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  const onClickFilterNotApproved = useCallback(() => {
    dispatch(
      setRiskWorkflowFilterState({
        status: RiskStatus.NOT_APPROVED,
        ...riskWorkflowFilterState,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  const onClickFilterNeedsReview = useCallback(() => {
    dispatch(
      setRiskWorkflowFilterState({
        status: RiskStatus.NEEDS_REVIEW,
        ...riskWorkflowFilterState,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  const risksProcessing = useMemo(() => {
    return filteredRisks?.filter((r) => r.processing_status === 0).length
  }, [filteredRisks])

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex justify-between items-center border-gray-100 border-y p-2 text-xs">
        <div className={'flex flex-wrap space-x-1'}>
          <input
            className={
              'resize-none rounded border-gray-200 p-1 px-3 text-sm text-gray-800 placeholder-gray-400'
            }
            type={'search'}
            placeholder="Search"
            onChange={onChangeSearchQuery}
            value={searchQuery}
          />
          <DocumentListboxMulti
            setSelectedDocuments={setSelectedDocuments}
            selectedDocuments={selectedDocuments ?? []}
            documents={documents}
          />
          <button
            onClick={onClickFilterApproved}
            className={`flex items-center space-x-1 rounded border px-3 py-1 hover:bg-gray-100  ${
              riskWorkflowFilterState?.status === RiskStatus.APPROVED
                ? 'bg-gray-100'
                : null
            }`}
          >
            <CheckIcon className="w-5 h-5" />
            <div>Approved</div>
            <span className="inline-flex items-center bg-gray-50 px-1.5 py-0.5 rounded-full ring-1 ring-gray-500/10 ring-inset font-medium text-gray-600 text-xs">
              {riskStatusStatsMapped.APPROVED}
            </span>
          </button>
          <button
            onClick={onClickFilterNotApproved}
            className={`flex items-center space-x-1 rounded border px-3 py-1 hover:bg-gray-100 ${
              riskWorkflowFilterState?.status === RiskStatus.NOT_APPROVED
                ? 'bg-gray-100'
                : null
            }`}
          >
            <XMarkIcon className="w-5 h-5" />
            <div>Not Approved</div>
            <span className="inline-flex items-center bg-gray-50 px-1.5 py-0.5 rounded-full ring-1 ring-gray-500/10 ring-inset font-medium text-gray-600 text-xs">
              {riskStatusStatsMapped.NOT_APPROVED}
            </span>
          </button>
          <button
            onClick={onClickFilterNeedsReview}
            className={`flex items-center space-x-1 rounded border px-3 py-1 hover:bg-gray-100  ${
              riskWorkflowFilterState?.status === RiskStatus.NEEDS_REVIEW
                ? 'bg-gray-100'
                : null
            }`}
          >
            <QuestionMarkCircleIcon className="w-5 h-5" />
            <div>Needs Review</div>
            <span className="inline-flex items-center bg-gray-50 px-1.5 py-0.5 rounded-full ring-1 ring-gray-500/10 ring-inset font-medium text-gray-600 text-xs">
              {riskStatusStatsMapped.NEEDS_REVIEW}
            </span>
          </button>
        </div>
        <div className={'flex items-center space-x-1'}>
          <RiskReviewSortListBox />
          <ExportPopover
            compact
            isDisabled={!risksToDisplay?.length || risksProcessing > 0}
            exportTypes={['excel', 'pdf']}
            onClickExportToExcel={onExcelExport}
            onClickExportToPDF={onExcelPDF}
          />
        </div>
      </div>
      {(filter.comment_author || filter.status || filter.comment_uuid) && (
        <div className="flex bg-gray-300 shadow-sm p-1 rounded w-fit text-xs">
          <span className="pr-1">Filtering by</span>{' '}
          {<FilterDisplay filter={filter} setFilter={setFilter} />}
        </div>
      )}
      <div className="flex h-full overflow-hidden">
        <div className="flex flex-col border-r w-48 text-sm overflow-auto">
          <RiskReviewListButton
            setSelectedRiskList={setSelectedRiskList}
            projectRiskList={null}
            selectedRiskList={selectedRiskList}
          />
          {getRiskList.map((list) => (
            <RiskReviewListButton
              key={list.id}
              setSelectedRiskList={setSelectedRiskList}
              projectRiskList={list}
              selectedRiskList={selectedRiskList}
            />
          ))}
        </div>
        <div className="flex-auto space-y-2 p-2 w-full overflow-auto">
          {risksToDisplay?.map((risk) => (
            <RiskReviewCard
              documents={documents ?? []}
              key={risk.id}
              projectRisk={risk}
            />
          ))}
          {!projectRisks && (
            <div className="p-2 text-gray-700 text-sm">Fetching Risks...</div>
          )}
          {projectRisks && risksToDisplay.length === 0 && (
            <div className="p-2 text-gray-700 text-sm">No risks to display</div>
          )}
        </div>
      </div>
      <Tooltip id={'risk-info-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default RiskReviewPage
