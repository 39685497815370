import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  DocumentChange,
  DocumentChangeComment,
  DocumentChangeTaskStatus,
} from '../../../shared/interfaces/project/document/changes/document-change.interface'
import Diff from '../comment-table/diff'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import {
  useCreateDocumentChangeCommentMutation,
  useGetDocumentsListByProjectQuery,
  useGetProjectPermissionsByIdQuery,
  useGetUserProfileQuery,
  useUpdateDocumentChangeCommentMutation,
  useUpdateDocumentChangesMutation,
} from '../../../redux/api-slice'
import { usePostHog } from 'posthog-js/react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../redux/application-slice'
import { POSTHOG } from '../../../utils/posthog-constants'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { setSelectedSources } from '../../../redux/viewer-slice'
import { Quads } from '../../../shared/interfaces/quads.interface'
import { ChangesContext } from './document-change-page'
import { Mention, MentionsInput } from 'react-mentions'
import DocumentChangePopover from '../../overlay/document-change-comment-popover'
import { getMentions } from '../../../utils/match-mentions'
import { useBackButton } from '../../../hooks/use-back-button'

interface DocumentChangeCardProps {
  documentChange: DocumentChange
  selectedDocumentChange: DocumentChange | null
  selectedDocument?: ProjectDocumentMetadata | null
  setSelectedDocumentChange: (documentChange: DocumentChange | null) => void
  index: number
  query?: string
}

const DocumentChangeCard: React.FC<DocumentChangeCardProps> = ({
  documentChange,
  selectedDocumentChange,
  selectedDocument,
  setSelectedDocumentChange,
  index,
  query,
}) => {
  const { setSize, windowDimensions } = useContext(ChangesContext)
  const currentProject = useSelector(selectCurrentProject)
  const [updateDocumentChanges] = useUpdateDocumentChangesMutation()
  const [commentSelected, setCommentSelected] =
    useState<DocumentChangeComment | null>(null)
  const posthog = usePostHog()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const root = useRef<HTMLDivElement | null>(null)
  const [localEditComment, setLocalEditComment] = useState('')
  const [localEditAddComment, setLocalEditAddComment] = useState('')
  const { data: profile } = useGetUserProfileQuery(undefined)
  const [updateDocumentChangesComment] =
    useUpdateDocumentChangeCommentMutation()
  const [addDocumentChangeComment] = useCreateDocumentChangeCommentMutation()
  const reset = useCallback(() => {
    setLocalEditComment('')
    setLocalEditAddComment('')
    setCommentSelected(null)
  }, [setCommentSelected])
  const { data: permissions } = useGetProjectPermissionsByIdQuery(
    currentProject?.id ?? skipToken
  )
  const { addToNavigationHistoryAndNavigate } = useBackButton()

  useEffect(() => {
    if (!root || !root.current || !setSize) {
      return
    }
    setSize(index, root.current.getBoundingClientRect().height)
  }, [setSize, windowDimensions?.width, index])

  const onUpdateTaskStatus = useCallback(
    (documentChange: DocumentChange, status: DocumentChangeTaskStatus) => {
      if (!documentChange || !documentChange?.id) {
        return
      }
      posthog?.capture(POSTHOG.document_change_status_updated)
      updateDocumentChanges({
        documentChange: { ...documentChange, status },
        queryParams: {
          projectId: currentProject?.uuid,
          query: query,
        },
      })
    },
    [posthog, updateDocumentChanges, currentProject?.uuid, query]
  )

  const onSelectDestination = useCallback(() => {
    const destinations = documentChange?.destinations
    if (!destinations?.length) {
      return
    }
    const firstDestination = destinations[0]
    if (!firstDestination?.document_uuid) {
      return
    }
    const highlightID = uuidv4()
    const documentChangePage = firstDestination.page
    const quads: Quads[] = firstDestination.quads
    addToNavigationHistoryAndNavigate(
      'supplementary-conditions',
      'supplementary-conditions',
      firstDestination?.document_uuid,
      firstDestination.page
    )

    searchParams.set('page', documentChangePage?.toString())
    setSearchParams(searchParams)

    dispatch(
      setSelectedSources([
        {
          id: highlightID,
          page: documentChangePage,
          quads: quads ?? [],
          isPrimary: true,
          documentUUID: firstDestination?.document_uuid,
        },
      ])
    )
  }, [
    addToNavigationHistoryAndNavigate,
    dispatch,
    documentChange?.destinations,
    searchParams,
    setSearchParams,
  ])

  const onSelectDocumentChange = useCallback(() => {
    setSelectedDocumentChange(documentChange)
    const foundDocument = documents?.find(
      (x) => x.id === documentChange.document
    )
    if (!foundDocument?.uuid) {
      return
    }

    navigate(
      `/${currentProject?.uuid}/supplementary-conditions/${
        foundDocument?.uuid ?? ''
      }`
    )

    const page = documentChange.source.page

    searchParams.set('page', page?.toString())

    setSearchParams(searchParams)

    const highlightID = uuidv4()

    const quads: Quads[] = documentChange?.source?.quads

    dispatch(
      setSelectedSources([
        {
          id: highlightID,
          page,
          quads: quads ?? [],
          isPrimary: true,
          documentUUID: foundDocument?.uuid,
        },
      ])
    )
  }, [
    dispatch,
    documentChange,
    documents,
    navigate,
    searchParams,
    setSearchParams,
    currentProject,
    setSelectedDocumentChange,
  ])

  const onClickNotApproved = useCallback(() => {
    const status: DocumentChangeTaskStatus =
      documentChange?.status === 'NOT_APPROVED' ? 'NO_STATUS' : 'NOT_APPROVED'
    onUpdateTaskStatus(documentChange, status)
  }, [documentChange, onUpdateTaskStatus])

  const onClickApproved = useCallback(() => {
    const status: DocumentChangeTaskStatus =
      documentChange?.status === 'APPROVED' ? 'NO_STATUS' : 'APPROVED'
    onUpdateTaskStatus(documentChange, status)
  }, [documentChange, onUpdateTaskStatus])

  const onClickBookmark = useCallback(() => {
    const status: DocumentChangeTaskStatus =
      documentChange?.status === 'IN_REVIEW' ? 'NO_STATUS' : 'IN_REVIEW'
    onUpdateTaskStatus(documentChange, status)
  }, [documentChange, onUpdateTaskStatus])

  const documentChangeIconContainer = useMemo(() => {
    return (
      <div className="flex flex-shrink-0">
        <button
          onClick={onClickApproved}
          className={`rounded-l border-y border-l px-3 py-1 text-sm  ${
            documentChange?.status === 'APPROVED'
              ? 'bg-green-300'
              : 'bg-white hover:bg-green-300'
          }`}
        >
          Addressed
        </button>
        <button
          onClick={onClickNotApproved}
          className={`border-y px-3 py-1 text-sm ${
            documentChange?.status === 'NOT_APPROVED'
              ? 'bg-red-300'
              : 'bg-white hover:bg-red-300'
          }`}
        >
          Not Addressed
        </button>
        <button
          onClick={onClickBookmark}
          className={`rounded-r border-y border-r px-3 py-1 text-sm ${
            documentChange?.status === 'IN_REVIEW'
              ? 'bg-orange-300 '
              : 'bg-white hover:bg-orange-300 hover:text-white'
          }`}
        >
          Review
        </button>
      </div>
    )
  }, [
    documentChange?.status,
    onClickApproved,
    onClickBookmark,
    onClickNotApproved,
  ])

  const onUpdateComment = useCallback(() => {
    reset()
    const mentions = getMentions(localEditComment)
    updateDocumentChangesComment({
      ...commentSelected,
      comment: localEditComment,
      document: selectedDocument ? selectedDocument?.uuid : undefined,
      project: !selectedDocument ? currentProject?.uuid : undefined,
      parent: documentChange,
      user: profile,
      mentions,
      page: documentChange.page,
      query,
    })
  }, [
    reset,
    commentSelected,
    localEditComment,
    selectedDocument,
    currentProject,
    updateDocumentChangesComment,
    profile,
    documentChange,
    query,
  ])

  const onSaveComment = useCallback(() => {
    posthog?.capture(POSTHOG.document_change_comment_edited)
    const mentions = getMentions(localEditAddComment)

    addDocumentChangeComment({
      comment: localEditAddComment,
      document_change_id: documentChange?.id,
      parent: documentChange,
      document: selectedDocument ? selectedDocument?.uuid : undefined,
      project: !selectedDocument ? currentProject?.uuid : undefined,
      user_created: profile?.id,
      user: profile,
      mentions,
      page: documentChange.page,
      query,
    })

    reset()
  }, [
    documentChange,
    reset,
    addDocumentChangeComment,
    localEditAddComment,
    selectedDocument,
    currentProject,
    profile,
    posthog,
    query,
  ])

  useEffect(() => {
    if (selectedDocumentChange?.id !== documentChange.id) {
      reset()
    }
  }, [selectedDocumentChange, documentChange.id, reset])

  const onCommentSelect = useCallback(
    (comment) => {
      setLocalEditComment(comment.comment ?? '')
      setCommentSelected(comment)
    },
    [setCommentSelected]
  )

  const onKeyDownSave = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onSaveComment()
      } else if (e.key === 'Enter' && e.shiftKey) {
        setLocalEditAddComment(`${localEditAddComment}\n`)
      } else if (e.key === 'Escape') {
        reset()
      }
    },
    [reset, localEditAddComment, onSaveComment]
  )

  const onKeyDownUpdate = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        onUpdateComment()
      } else if (e.key === 'Enter' && e.shiftKey) {
        setLocalEditComment(`${localEditComment}\n`)
      } else if (e.key === 'Escape') {
        reset()
      }
    },
    [localEditComment, onUpdateComment, reset]
  )

  const onChangeLocalEditComment = useCallback((e) => {
    setLocalEditComment(e.target.value)
  }, [])

  const onLocalEditCommentAdd = useCallback((e) => {
    if (e.target.value.slice(-1) !== '\n') {
      setLocalEditAddComment(e.target.value)
    }
  }, [])

  const renderSuggestion = useCallback((_, __, highlightedDisplay) => {
    return <div>{highlightedDisplay}</div>
  }, [])

  const renderComment = useCallback((comment: DocumentChangeComment) => {
    const mentions = comment.mentions ?? []
    const elements: JSX.Element[] = []
    let index = 0
    if (!mentions || mentions.length === 0) {
      return <span>{comment.comment}</span>
    }
    for (const mention of mentions) {
      if (
        mention.start_index === undefined ||
        mention.end_index === undefined
      ) {
        continue
      }
      elements.push(
        <span key={`mention_${mention.user_targeted_id}_${index}`}>
          {comment.comment?.slice(index, mention.start_index)}
        </span>
      )
      elements.push(
        <span
          key={`mention_${mention.user_targeted_id}_${index}_mention`}
          className="text-indigo-500"
        >
          {mention.user_targeted?.email}
        </span>
      )
      index = mention.end_index
    }
    elements.push(
      <span key={`mention_${mentions.length}_${index}`}>
        {comment.comment?.slice(index)}
      </span>
    )
    return elements
  }, [])

  return (
    <div className="text-xs" id={`document_change_card_${documentChange?.id}`}>
      <div ref={root} className="mb-4 flex flex-col rounded border bg-white">
        <div className="flex items-start justify-between p-2">
          <div className="h-full w-full text-left font-semibold">
            <div>{documentChange?.original_clause_reference}</div>
            <div className="text-sm">{documentChange?.change_type}</div>
          </div>
          {documentChangeIconContainer}
        </div>
        <div className="flex flex-col overflow-hidden whitespace-pre-wrap p-2">
          <div>
            <Diff
              inputA={documentChange?.original_clause ?? ''}
              inputB={documentChange?.reconciled_clause ?? ''}
            />
          </div>
          <div className="flex justify-end space-x-2 text-gray-700">
            <button
              className="self-end text-left text-xs hover:underline"
              onClick={onSelectDocumentChange}
            >
              Show source
            </button>
            {documentChange?.destinations?.length ? (
              <button
                className="self-end text-left text-xs hover:underline"
                onClick={onSelectDestination}
              >
                Show destination
              </button>
            ) : null}
          </div>
        </div>
        <hr />
        <div className="h-full w-full overflow-auto p-2">
          <div className="font-semibold">Explanation</div>
          <div className="">{documentChange?.explanation}</div>
        </div>
        <hr />
        <div className={'p-2'}>
          <div className="w-full space-y-2">
            {documentChange?.comments?.map((comment) => (
              <div
                key={`document_change_comments_${documentChange.id}_${comment.id}`}
                className="group flex justify-between"
              >
                <div className="flex-grow">
                  <div className={'flex items-end justify-start space-x-1'}>
                    <div className={'font-semibold'}>
                      {comment?.user?.first_name} {comment?.user?.last_name}
                    </div>
                    <div className="hidden text-sm text-gray-400 group-hover:block">
                      {`${new Date(
                        comment?.date_created || new Date().toISOString()
                      ).toDateString()}`}
                    </div>
                  </div>
                  {commentSelected?.id === comment.id && comment.id ? (
                    <MentionsInput
                      className={'mentions'}
                      placeholder="Write a comment"
                      onChange={onChangeLocalEditComment}
                      value={localEditComment}
                      onKeyDown={onKeyDownUpdate}
                    >
                      <Mention
                        trigger="@"
                        appendSpaceOnAdd
                        data={
                          permissions?.users
                            ?.filter?.((u) => u.email)
                            ?.map((u) => {
                              return {
                                id: u.id,
                                display: u.email,
                              }
                            }) ?? []
                        }
                        renderSuggestion={renderSuggestion}
                      />
                    </MentionsInput>
                  ) : (
                    <div
                      className={'whitespace-pre-wrap'}
                      key={`document_change_comment_${comment?.id}`}
                    >
                      {renderComment(comment)}
                    </div>
                  )}
                </div>
                {profile?.id === comment.user?.id && comment.id && (
                  <DocumentChangePopover
                    setCommentSelected={onCommentSelect}
                    documentChangeComment={comment}
                    documentChange={documentChange}
                    projectId={currentProject?.uuid}
                    documentId={selectedDocument?.uuid}
                    query={query}
                  />
                )}
              </div>
            ))}
            <div className="text-md">
              <MentionsInput
                className={'mentions'}
                onChange={onLocalEditCommentAdd}
                placeholder="Write a comment"
                value={localEditAddComment}
                onKeyDown={onKeyDownSave}
              >
                <Mention
                  trigger="@"
                  appendSpaceOnAdd
                  data={
                    permissions?.users
                      ?.filter?.((u) => u.email)
                      ?.map((u) => {
                        return {
                          id: u.id,
                          display: u.email,
                        }
                      }) ?? []
                  }
                  renderSuggestion={renderSuggestion}
                />
              </MentionsInput>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentChangeCard
