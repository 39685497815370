import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  useGetDocumentByUuidQuery,
  useGetDocumentProcessingStatusQuery,
} from '../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentDocumentStatus,
  selectCurrentProject,
  setCurrentDocument,
  setCurrentDocumentStatus,
} from '../redux/application-slice'
import { DocumentViewerContext } from '../contexts/document-viewer-instance-context'
import {
  SelectedSource,
  TemporaryHighlight,
  selectCurrentPage,
  selectCustomLabelOpen,
  selectIconMenuOpen,
  selectNewRevision,
  selectOpenRevision,
  selectSelectedRevision,
  selectSelectedSources,
  selectTextSelected,
  setClickCoords,
  setCurrentPage,
  setIconMenuOpen,
  setIsSearching,
  setNewRevision,
  setOpenRevision,
  setSelectedBlacklineSegments,
  setSelectedRevision,
  setDocumentLoaded,
  setTemporaryHighlight,
  setTextSelected,
  setTotalPages,
  setZoomLevel,
  selectDocumentLoaded,
} from '../redux/viewer-slice'
import { Revision } from '../shared/interfaces/project/document/revision/revision.interface'
import { useHotkeys } from 'react-hotkeys-hook'
import { DocumentBlacklineSegment } from '../shared/interfaces/project/document/blackline/document-blackline.interface'
import debounce from 'lodash/debounce'
import { POSTHOG } from '../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { useGetRevisionsQuery } from '../redux/api/api-revisions-slice'

export const useDocumentViewer = (viewer, scrollView) => {
  const documentLoaded = useSelector(selectDocumentLoaded)
  const newRevision = useSelector(selectNewRevision) as Revision | null
  const openRevision = useSelector(selectOpenRevision)
  const currentPage = useSelector(selectCurrentPage)
  const pageWidgetContainer = window?.document
    ?.getElementById('webviewer-wrapper')
    ?.querySelector(`#pageWidgetContainer${currentPage}`) as HTMLDivElement
  const pageSection = window?.document
    ?.getElementById('webviewer-wrapper')
    ?.querySelector(`#pageSection${currentPage}`) as HTMLDivElement
  const selectedRevision = useSelector(selectSelectedRevision)
  const customLabelOpen = useSelector(selectCustomLabelOpen)
  const iconMenuOpen = useSelector(selectIconMenuOpen)
  const currentDocument = useSelector(selectCurrentDocument)
  const textSelected = useSelector(selectTextSelected)
  const selectedSources = useSelector(selectSelectedSources)
  const documentViewerContext = useContext(DocumentViewerContext)
  const { documentViewer } = documentViewerContext
  const navigation = useNavigate()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const posthog = usePostHog()
  const openRevisionRef = useRef({})
  const customLabelRef = useRef({})
  const iconMenuOpenRef = useRef({})
  const lastClickedRef = useRef<HTMLElement | null>(null)
  const [renderedPages, setRenderedPages] = useState<number[]>([])
  const renderedPagesRef = useRef<number[]>(renderedPages)
  const selectedSourceRef = useRef<SelectedSource[] | null>(null)
  const currentDocumentStatus = useSelector(selectCurrentDocumentStatus)
  const [selectedBlacklineElement, setSelectedBlacklineElement] =
    useState<HTMLButtonElement | null>(null)
  const { documentId, projectId } = useParams<{
    documentId: string
    projectId: string
  }>()
  const { currentData: revisionsData } = useGetRevisionsQuery(
    currentDocument ? { documentIds: [currentDocument.id] } : skipToken
  )
  const selectedRevisionRef = useRef<Revision | null>(null)
  const { data: documentProcessingStatus } =
    useGetDocumentProcessingStatusQuery(documentId ? documentId : skipToken, {
      pollingInterval:
        currentDocumentStatus?.is_qa_complete &&
        currentDocumentStatus.is_toc_complete &&
        currentDocumentStatus.is_typesense_complete &&
        currentDocumentStatus.is_definitions_complete &&
        currentDocumentStatus.is_sections_complete
          ? undefined
          : 5000,
    })
  const [documentPollingInterval, setDocumentPollingInterval] = useState<
    number | undefined
  >(undefined)
  const currentProject = useSelector(selectCurrentProject)
  const { currentData: currentDocumentData, isError: documentError } =
    useGetDocumentByUuidQuery(documentId ?? skipToken, {
      pollingInterval: documentPollingInterval,
      refetchOnMountOrArgChange: 60 * 60,
    })
  useEffect(() => {
    if (documentId && documentError && currentProject?.uuid) {
      navigation(`${currentProject?.uuid}/documents`)
    }
  }, [documentError, documentId, currentProject?.uuid, navigation])

  useEffect(() => {
    dispatch(setCurrentDocument(null))
    documentViewer?.closeDocument()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  useEffect(() => {
    dispatch(setCurrentDocumentStatus(documentProcessingStatus))
  }, [documentProcessingStatus, dispatch])
  // // End document status

  useEffect(() => {
    setTimeout(() => {
      openRevisionRef.current = Boolean(openRevision || newRevision)
    }, 50)
  }, [openRevision, newRevision])

  useEffect(() => {
    setTimeout(() => {
      selectedRevisionRef.current = selectedRevision
    }, 50)
  }, [selectedRevision])

  useEffect(() => {
    selectedSourceRef.current = null
    dispatch(setTemporaryHighlight(null))
  }, [dispatch, documentId])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSelectedRevision(newRevision))
    }, 100)
  }, [newRevision, dispatch])

  useEffect(() => {
    customLabelRef.current = customLabelOpen
  }, [customLabelOpen])

  useEffect(() => {
    //very small timeout to support order of operations between click callback and risk menu flag being updated
    setTimeout(() => {
      iconMenuOpenRef.current = iconMenuOpen
    }, 50)
  }, [iconMenuOpen])

  useEffect(() => {
    if (!currentDocumentData) {
      return
    }
    // If the document has been loaded yet, e.g. link was used to get to this page directly
    // Also refresh if the document has been updated with an optimized file
    if (
      currentDocument?.id !== currentDocumentData?.id ||
      (currentDocument?.id === currentDocumentData?.id &&
        !currentDocument?.optimized_file &&
        currentDocumentData?.optimized_file)
    ) {
      dispatch(setCurrentDocument(currentDocumentData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentDocumentData])

  useEffect(() => {
    dispatch(setSelectedBlacklineSegments(null))
  }, [currentPage, dispatch])

  useEffect(() => {
    dispatch(setCurrentDocument(currentDocumentData ?? null))
    if (
      !currentDocumentData?.optimized_file &&
      currentDocumentData?.file?.toLocaleLowerCase()?.includes('.docx')
    ) {
      setDocumentPollingInterval(5000)
    } else {
      setDocumentPollingInterval(undefined)
    }
  }, [currentDocumentData, dispatch])

  useEffect(() => {
    dispatch(setTextSelected(null))
  }, [currentDocument, dispatch])

  const setRenderedPagesRef = (data) => {
    renderedPagesRef.current = data
    setRenderedPages(data)
  }

  useEffect(() => {
    return () => {
      documentViewer?.closeDocument()
      dispatch(setCurrentDocument(null))
    }
  }, [dispatch, documentViewer])

  const onEscape = useCallback(() => {
    dispatch(setTemporaryHighlight(null))
  }, [dispatch])

  useHotkeys('ctrl+c', async () => {
    if (!textSelected) {
      return
    }
    const textToCopy = textSelected?.text?.replaceAll('\n', ' ') ?? ''
    await navigator.clipboard.writeText(textToCopy)
  })

  useHotkeys('meta+c', async () => {
    if (!textSelected) {
      return
    }
    const textToCopy = textSelected?.text?.replaceAll('\n', ' ') ?? ''
    await navigator.clipboard.writeText(textToCopy)
  })

  useHotkeys('esc', onEscape, [onEscape])

  const highlightSources = useCallback(() => {
    if (!selectedSourceRef.current) {
      return
    }
    const selectedSource = selectedSourceRef.current
    const temporaryHighlights: TemporaryHighlight[] = []
    for (const source of selectedSource) {
      temporaryHighlights.push({
        page: source.page,
        quads: source.quads,
        id: source.id,
        pdfCoords: source?.quads?.[0]?.y3 > source?.quads?.[0]?.y2,
        colourClass: source.isPrimary ? 'bg-yellow-400' : 'bg-yellow-200',
      })
    }
    dispatch(setTemporaryHighlight(temporaryHighlights))
    const primarySource = selectedSource.find((s) => s.isPrimary)
    if (!primarySource) {
      return
    }
    setTimeout(() => {
      documentViewer?.setCurrentPage(primarySource.page, false)
      const el = window?.document?.getElementById('webviewer-wrapper')
        ?.parentNode as HTMLDivElement
      if (!el) {
        return
      }
      const highlightEl = document.getElementById(primarySource.id)
      if (!highlightEl) {
        return
      }
      highlightEl.scrollIntoView({
        block: 'start',
      })
      el.scrollTop -= 200
    }, 200)
  }, [dispatch, documentViewer])

  useEffect(() => {
    if (!selectedSources?.length || !documentLoaded) {
      return
    }
    const sourceDocumentUUID = selectedSources[0].documentUUID
    if (currentDocument?.uuid !== sourceDocumentUUID) {
      return
    }
    selectedSourceRef.current = selectedSources
    highlightSources()
  }, [selectedSources, highlightSources, currentDocument?.uuid, documentLoaded])

  useEffect(() => {
    const pageCompleteCallback = (pageNumber: number) => {
      if (!renderedPagesRef.current.includes(pageNumber)) {
        setRenderedPagesRef([...renderedPagesRef.current, pageNumber])
      }
    }
    const textSelectedCallback = debounce((quads, text, pageNumber) => {
      if (customLabelRef.current) {
        return
      }
      if (quads && quads.length > 0) {
        const textSelected = documentViewer?.getSelectedText()
        const quadsSelected = documentViewer?.getSelectedTextQuads()
        dispatch(
          setTextSelected({
            quads: quadsSelected,
            text: textSelected,
            pageNumber,
          })
        )
        posthog?.capture(POSTHOG.text_selected, {
          document_uuid: currentDocument?.uuid,
        })
      } else {
        dispatch(setTextSelected(null))
      }
    }, 100)
    const pageNumberUpdatedCallback = (pageNumber) => {
      dispatch(setCurrentPage(pageNumber))
      debounce(() => {
        posthog?.capture(POSTHOG.page_changed, {
          page: pageNumber,
          document_uuid: currentDocument?.uuid,
          project_uuid: currentProject?.uuid,
        })
      }, 1000)()
    }
    const documentClickCallback = (e: React.MouseEvent<HTMLElement>) => {
      if (selectedRevisionRef?.current?.id) {
        const revisionSideling = document.getElementById(
          selectedRevisionRef.current.id.toString()
        )
        if (!revisionSideling) {
          return
        }
        let trueCount = 0
        const revisionSidelings =
          document.querySelectorAll('.revision-sideling')
        let foundRevision: Element | null = null
        for (const revisionSideling of revisionSidelings) {
          if (revisionSideling.contains(e.target as HTMLElement)) {
            trueCount += 1
            foundRevision = revisionSideling.closest('.revision-sideling')
            break
          }
        }
        const foundRevisionID = foundRevision?.getAttribute('data-id') ?? null
        const foundRevisionData = revisionsData?.find(
          (r) => r.id === parseFloat(foundRevisionID ?? '')
        )
        if (
          (trueCount === 0 && !foundRevisionID) ||
          foundRevisionID?.toString() !==
            selectedRevisionRef?.current?.id?.toString()
        ) {
          if (foundRevisionData) {
            dispatch(setSelectedRevision(foundRevisionData))
          } else {
            dispatch(setSelectedRevision(null))
          }
        }
      }
      if (openRevisionRef.current) {
        let trueCount = 0
        const revisionSidelings =
          document.querySelectorAll('.revision-sideling')
        for (const revisionSideling of revisionSidelings) {
          if (revisionSideling.contains(e.target as HTMLElement)) {
            trueCount += 1
            break
          }
        }
        if (trueCount === 0 && !openRevisionRef.current) {
          dispatch(setOpenRevision(null))
          dispatch(setNewRevision(null))
        }
      }
      if (iconMenuOpenRef.current !== null) {
        dispatch(setIconMenuOpen(null))
      }
      const pageMatch = (e?.target as HTMLElement)?.offsetParent?.id?.match(
        /\d+/
      )
      if (pageMatch) {
        dispatch(
          setClickCoords({
            x: e.clientX,
            y: e.clientY,
            page: parseFloat(pageMatch[0]),
          })
        )
        lastClickedRef.current = e.target as HTMLElement
      }
    }

    const documentUnloadedCallback = () => {
      dispatch(setDocumentLoaded(false))
    }

    const beforeDocumentLoadedCallback = () => {
      dispatch(setDocumentLoaded(false))
    }

    const documentLoadedCallback = () => {
      dispatch(setDocumentLoaded(true))
      const fitMode = 'FitMode'
      documentViewer?.setFitMode(documentViewer[fitMode].FitPage)
      setRenderedPagesRef([])
      highlightSources()
    }

    const searchInProgressCallback = (inProgress: boolean) => {
      dispatch(setIsSearching(inProgress))
    }

    if (documentViewer) {
      if (!scrollView.current || !viewer.current) {
        return
      }
      // Attach viewer elements
      documentViewer?.setScrollViewElement(scrollView.current)
      documentViewer?.setViewerElement(viewer.current)

      dispatch(setZoomLevel(documentViewer?.getZoomLevel()))
      documentViewer?.enableAnnotations()

      // Set default tool
      documentViewer?.setToolMode(documentViewer?.getTool('TextSelect'))

      // Hook up event listeners
      documentViewer?.addEventListener('pageComplete', pageCompleteCallback)
      documentViewer?.addEventListener('textSelected', textSelectedCallback)
      documentViewer?.addEventListener(
        'pageNumberUpdated',
        pageNumberUpdatedCallback
      )
      documentViewer?.addEventListener('documentLoaded', documentLoadedCallback)
      documentViewer?.addEventListener(
        'beforeDocumentLoaded',
        beforeDocumentLoadedCallback
      )
      documentViewer?.addEventListener(
        'documentUnloaded',
        documentUnloadedCallback
      )
      documentViewer?.addEventListener('click', documentClickCallback)
      documentViewer?.setSearchHighlightColors({
        // setSearchHighlightColors accepts both Annotations.Color objects or 'rgba' strings
        searchResult: 'rgba(0, 255, 0, 0.5)',
        activeSearchResult: 'rgba(0, 255, 0, 0.5)',
      })
      documentViewer?.addEventListener(
        'searchInProgress',
        searchInProgressCallback
      )
    }
    return () => {
      if (documentViewer) {
        documentViewer?.removeEventListener(
          'textSelected',
          textSelectedCallback
        )
        documentViewer?.removeEventListener(
          'pageNumberUpdated',
          pageNumberUpdatedCallback
        )
        documentViewer?.removeEventListener(
          'pageComplete',
          pageCompleteCallback
        )
        documentViewer?.removeEventListener(
          'documentLoaded',
          documentLoadedCallback
        )
        documentViewer?.removeEventListener('click', documentClickCallback)
      }
    }
    // eslint-disable-next-line
  }, [documentViewer, setZoomLevel])

  useEffect(() => {
    if (currentDocumentData && currentDocument) {
      const fileUrl =
        currentDocumentData.optimized_file ?? currentDocumentData.file
      documentViewer
        ?.loadDocument(fileUrl, {
          licenseKey: atob(
            'UHJvdmlzaW9uIFNvZnR3YXJlIENvcnBvcmF0aW9uIChnZXRwcm92aXNpb24uY28pOk9FTTpQcm92aXNpb246OkIrOkFNUygyMDIzMDcxNyk6RkFBNTQ5MkQwNDg3ODgwQUYzNjBCMTNBQzlBMjczNzg2MDYxMkY4NUY3MTgxQUEwREQwNDdCOEFBRDRDNkU5MDNBOTQzMUY1Qzc='
          ),
        })
        .then(() => {
          dispatch(setTotalPages(documentViewer?.getPageCount()))
        })
        .catch((e) => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocumentData, documentViewer, dispatch, currentDocument])

  useEffect(() => {
    const page = searchParams.get('page')
    if (!documentLoaded || !page) {
      return
    }
    documentViewer?.setCurrentPage(parseInt(page), false)
  }, [searchParams, documentViewer, documentLoaded])

  useEffect(() => {
    if (currentDocument?.uuid) {
      posthog?.capture(POSTHOG.document_opened, {
        document_uuid: currentDocument?.uuid,
      })
    }
  }, [currentDocument?.uuid, posthog])

  useEffect(() => {
    if (!currentDocument) {
      return
    }
    if (currentDocument.project !== currentProject?.id) {
      documentViewer?.closeDocument()
    }
  }, [currentProject, documentViewer, currentDocument])

  const sidelingLeftOffset = useMemo(() => {
    return pageSection?.offsetWidth
      ? `${
          pageSection?.offsetWidth / 2 + pageWidgetContainer?.clientWidth / 2
        }px`
      : '100%'
  }, [pageSection?.offsetWidth, pageWidgetContainer?.clientWidth])

  const onSelectBlackline = useCallback(
    (blacklines: DocumentBlacklineSegment[], ref: HTMLButtonElement | null) => {
      setSelectedBlacklineElement(ref)
      dispatch(setSelectedBlacklineSegments(blacklines))
    },
    [dispatch]
  )

  return {
    onSelectBlackline,
    sidelingLeftOffset,
    selectedBlacklineElement,
    renderedPages,
    lastClickedRef,
  }
}
