import React, { useCallback } from 'react'

import { Tooltip } from 'react-tooltip'
import { useNavigate } from 'react-router-dom'

import CreateRiskListForm from './create-risk-list-form'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'

const CustomRiskListsPage = () => {
  const navigate = useNavigate()

  const handleCloseCreateRiskListForm = useCallback(() => {
    navigate('..')
  }, [navigate])

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="w-full flex-auto space-y-2 overflow-auto p-2">
        <div className="mb-4 flex items-center justify-between">
          <button
            type="button"
            className="justify-left flex items-center rounded bg-white px-3 py-1.5 text-xs font-semibold text-gray-700 hover:bg-gray-100"
            onClick={handleCloseCreateRiskListForm}
          >
            <ArrowLeftIcon className="mr-2 h-5 w-5" />
            Back to Risk Review
          </button>
        </div>
        <CreateRiskListForm onClose={handleCloseCreateRiskListForm} />
      </div>

      <Tooltip id={'risk-info-id'} style={{ zIndex: 100 }} />
    </div>
  )
}

export default CustomRiskListsPage
