import React, { useCallback } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useDeleteRiskListMutation } from '../../redux/api/project-risk-api-slice'
import { toast } from 'react-toastify'
import { selectModal, setModal } from '../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import DeleteModal from './delete-modal'

interface DeleteRiskListModalProps {
  open: boolean
}

const DeleteRiskListModal: React.FC<DeleteRiskListModalProps> = ({ open }) => {
  const [deleteRiskList] = useDeleteRiskListMutation()
  const dispatch = useDispatch()
  const onClose = useCallback(() => {
    dispatch(setModal(null))
  }, [dispatch])
  const modal = useSelector(selectModal)

  const handleDelete = useCallback(async () => {
    try {
      await deleteRiskList(modal?.props?.id).unwrap()
      onClose()
      toast.success('Risk list deleted successfully')
    } catch (error) {
      toast.error('Something went wrong. Please try again.')
    }
  }, [deleteRiskList, modal?.props?.id, onClose])

  return (
    <DeleteModal
      open={open}
      onClose={onClose}
      title="Delete Risk List"
      body={
        <p className="text-sm text-gray-500">
          Are you certain you want to delete &quot;{modal?.props?.name}&quot;
          and all associated risk definitions? This action cannot be undone.
        </p>
      }
      icon={
        <ExclamationTriangleIcon
          className="h-6 w-6 text-red-600"
          aria-hidden="true"
        />
      }
      confirmText="Delete"
      cancelText="Cancel"
      onConfirm={handleDelete}
    />
  )
}

export default DeleteRiskListModal
