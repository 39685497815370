export const POSTHOG = {
  user_logged_in: 'User logged in',
  user_logged_out: 'User logged out',
  command_bar_opened: 'Command bar opened',
  document_uploaded: 'Document uploaded',
  document_revision_uploaded: 'Document revision uploaded',
  document_deleted: 'Document deleted',
  document_opened: 'Document opened',
  document_renamed: 'Document renamed',
  chat_renamed: 'Chat renamed',
  project_created: 'Project created',
  invite_users: 'Invite Users',
  project_deleted: 'Project deleted',
  project_renamed: 'Project renamed',
  project_opened: 'Project opened',
  question_submitted: 'Question submitted',
  question_source_clicked: 'Question source clicked',
  keyword_search_submitted: 'Keyword search submitted',
  toc_item_clicked: 'TOC item clicked',
  keyword_search_result_clicked: 'Keyword search result clicked',
  page_changed: 'Page changed',
  text_selected: 'Text selected',
  comment_created_opened: 'Comment create opened',
  revision_created_opened: 'Revision create opened',
  revision_created: 'Revision created',
  highlight_created: 'Highlight created',
  bookmark_created: 'Bookmark created',
  page_thumbnail_clicked: 'Page thumbnail clicked',
  definition_clicked: 'Definition clicked',
  section_reference_clicked: 'Section reference clicked',
  preview_pane_definition_clicked: 'Preview pane definition clicked',
  preview_pane_section_reference_clicked:
    'Preview pane section reference clicked',
  question_response_received: 'Question response received',
  keyword_search_finished: 'Keyword search finished',
  preview_pane_navigated_back: 'Preview pane navigated back',
  download_comment_table: 'Download comment table',
  comment_table_edited: 'Comment table edited',
  bookmark_clicked: 'Bookmark clicked',
  custom_label_clicked: 'Custom label clicked',
  revision_edited: 'Revision edited',
  revision_deleted: 'Revision deleted',
  scroll_bar_event: 'Scroll bar event',
  question_feedback_submitted: 'Question feedback submitted',
  project_typesense_search: 'Project typesense search',
  document_typesense_search: 'Document typesense search',
  document_typesense_result_clicked: 'Document typesense result clicked',
  project_typesense_result_clicked: 'Project typesense result clicked',
  checklist_create: 'Checklist Create',
  checklist_run_all_questions: 'Checklist run all questions',
  checklist_question_updated: 'Checklist question updated',
  chat_feedback_submitted: 'Chat feedback submitted',
  clause_filtering_opened: 'Clause filtering opened',
  custom_label_created: 'Custom label created',
  custom_label_applied: 'Custom label applied',
  chat_sent_message: 'Chat sent message',
  chat_session_saved: 'Chat session saved',
  chat_session_opened: 'Chat session opened',
  risk_labelling_opened: 'Risk labelling opened',
  risk_labelling_label_opened: 'Risk labelling label opened',
  chat_feedback_thumbs_up: 'Chat feedback thumbs up',
  chat_feedback_thumbs_down: 'Chat feedback thumbs down',
  amendment_clicked: 'Amendment clicked',
  document_change_comment_edited: 'Document change comment edited',
  document_change_status_updated: 'Document change status updated',
  project_risk_comment_edited: 'Project risk comment edited',
  project_risk_status_updated: 'Project risk status updated',
  chat_slow: 'Chat slow',
  chat_source_clicked: 'Chat source clicked',
  export_risk_excel: 'Export Risk to Excel',
  export_risk_pdf: 'Export Risk to PDF',
  risk_review_workflow_opened: 'Risk Review workflow opened',
  documents_workflow_opened: 'Documents workflow opened',
  chat_workflow_opened: 'Chat workflow opened',
  comments_workflow_opened: 'Comments workflow opened',
  clause_filtering_workflow_opened: 'Clause filtering workflow opened',
  supplementary_conditions_workflow_opened:
    'Supplementary conditions workflow opened',
  risk_review_risk_expanded: 'Risk Review risk expanded',
  explain_this_clause_clicked: 'Explain this clause clicked',
  risk_review_item_feedback_thumbs_up: 'Risk Review item feedback thumbs up',
  risk_review_item_feedback_thumbs_down:
    'Risk Review item feedback thumbs down',
  risk_review_item_status_approved: 'Risk Review item status approved',
  risk_review_item_status_not_approved: 'Risk Review item status not approved',
  risk_review_list_opened: 'Risk Review list opened',
  duplicator_opened: 'Duplicator opened',
  revision_status_changed: 'Revision status changed',
  document_chat_conflicts_opened: 'Document chat conflicts opened',
  folder_deleted: 'Folder deleted',
  folder_created: 'Folder created',
  folder_renamed: 'Folder renamed',
  supplementary_condition_qa_updated: 'Supplementary condition QA updated',
  supplementary_condition_qa_deleted: 'Supplementary condition QA deleted',
  supplementary_condition_qa_created: 'Supplementary condition QA created',
}
